<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <GiftInputOptions
        @createCashGift="createCashGift"
        @createPropertyGift="createPropertyGift"
        @createOtherGift="createOtherGift"
        @createVehicleGift="createVehicleGift"
        @createCharityGift="createCharityGift"
      ></GiftInputOptions>
      <GiftTable
        v-show="
          cashGifts.length ||
          vehicleGifts.length ||
          otherGifts.length ||
          charityGifts.length ||
          propertyGifts.length
        "
        :cash="cashGifts"
        :vehicle="vehicleGifts"
        :other="otherGifts"
        :charity="charityGifts"
        :property="propertyGifts"
        @editCashGift="editCashGift"
        @deleteCashGift="deleteCashGift"
        @editPropertyGift="editPropertyGift"
        @deletePropertyGift="deletePropertyGift"
        @editOtherGift="editOtherGift"
        @deleteOtherGift="deleteOtherGift"
        @editCharityGift="editCharityGift"
        @deleteCharityGift="deleteCharityGift"
        @editVehicleGift="editVehicleGift"
        @deleteVehicleGift="deleteVehicleGift"
      >
      </GiftTable>
      <GiftCashModal
        v-show="show.cashModal"
        @close="closeCashModal"
        :show="show.cashModal"
        :edit="selected.cashGift"
        :after="true"
      ></GiftCashModal>
      <GiftPropertyModal
        v-show="show.propertyModal"
        @close="closePropertyModal"
        :show="show.propertyModal"
        :edit="selected.propertyGift"
        :after="true"
      ></GiftPropertyModal>
      <GiftVehicleModal
        v-show="show.vehicleModal"
        @close="closeVehicleModal"
        :show="show.vehicleModal"
        :edit="selected.vehicleGift"
        :after="true"
      ></GiftVehicleModal>
      <GiftCharityModal
        v-show="show.charityModal"
        @close="closeCharityModal"
        :show="show.charityModal"
        :edit="selected.charityGift"
        :after="true"
      ></GiftCharityModal>
      <GiftOtherModal
        v-show="show.otherModal"
        @close="closeOtherModal"
        :show="show.otherModal"
        :edit="selected.otherGift"
        :after="true"
      >
      </GiftOtherModal>
      <DeleteModal
        v-show="show.deleteModal"
        @close="closeDeleteModal"
        @confirm="deleteGift"
        v-bind:bodyText="deleteText"
      >
      </DeleteModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import GiftTable from './Table/Table'
import GiftInputOptions from './Table/InputOptions'
import GiftCashModal from './Modals/Cash'
import GiftPropertyModal from './Modals/Property'
import GiftVehicleModal from './Modals/Vehicle'
import GiftCharityModal from './Modals/Charity'
import GiftOtherModal from './Modals/Other'
import DeleteModal from '@/common/components/DeleteModal'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { http } from '@/services'

export default {
  name: 'GiftsIndirect',
  mixins: [willStringHelpers],
  components: {
    NavQuestion,
    NavQuestionButtons,
    GiftTable,
    GiftInputOptions,
    GiftCashModal,
    DeleteModal,
    GiftPropertyModal,
    GiftVehicleModal,
    GiftCharityModal,
    GiftOtherModal
  },
  computed: {
    isValidated() {
      if (
        this.cashGifts.length ||
        this.otherGifts.length ||
        this.vehicleGifts.length ||
        this.propertyGifts.length ||
        this.charityGifts.length
      )
        return true
      return false
    },
    heading() {
      return 'What would you like to gift?'
    },
    partner() {
      return this.$store.getters.partner
    },
    subHeading() {
      return (
        'Anything that you own can be gifted, from items of jewellery to shares in a business. Remember ' +
        'that these gifts will pass only when yourself and ' +
        this.partner.full_name +
        ' have passed away.'
      )
    },
    number() {
      if (this.details.gifts_before) return '5'
      return '4'
    },
    forwardTo() {
      return '/gifts/check_and_confirm'
    },
    backTo() {
      return '/gifts/indirect_option'
    },
    details() {
      return this.$store.getters.details
    },
    cashGifts() {
      if (this.$store.getters.giftCash)
        return this.$store.getters.giftCash.filter(
          (gift) => gift.gift_after_partner
        )
      return null
    },
    otherGifts() {
      if (this.$store.getters.giftOther)
        return this.$store.getters.giftOther.filter(
          (gift) => gift.gift_after_partner
        )
      return null
    },
    charityGifts() {
      if (this.$store.getters.giftCharity)
        return this.$store.getters.giftCharity.filter(
          (gift) => gift.gift_after_partner
        )
      return null
    },
    vehicleGifts() {
      if (this.$store.getters.giftVehicle)
        return this.$store.getters.giftVehicle.filter(
          (gift) => gift.gift_after_partner
        )
      return null
    },
    propertyGifts() {
      if (this.$store.getters.giftRealEstate)
        return this.$store.getters.giftRealEstate.filter(
          (gift) => gift.gift_after_partner
        )
      return null
    },
    deleteText() {
      if (this.selected.cashGift) {
        var cash = 'Delete gift of £' + this.selected.cashGift.amount + ' to '
        if (this.selected.cashGift.beneficiary_group)
          cash += 'your ' + this.selected.cashGift.beneficiary_group
        else if (this.selected.cashGift.beneficiary.full_name)
          cash += this.selected.cashGift.beneficiary.full_name
        return cash
      } else if (this.selected.propertyGift) {
        var property =
          'Delete gift of ' +
          this.selected.propertyGift.address_line_1 +
          ', ' +
          this.selected.propertyGift.post_code +
          ' to '
        if (this.selected.propertyGift.beneficiary_group)
          property += 'your ' + this.selected.propertyGift.beneficiary_group
        else if (this.selected.propertyGift.beneficiary.full_name)
          property += this.selected.propertyGift.beneficiary.full_name
        return property
      } else if (this.selected.vehicleGift) {
        var car =
          'Delete your gift of the ' +
          this.selected.vehicleGift.make +
          ', ' +
          this.selected.vehicleGift.model +
          ' to ' +
          this.selected.vehicleGift.beneficiary.full_name
        return car
      } else if (this.selected.otherGift) {
        var other =
          'Delete your gift of your ' + this.selected.otherGift.item + ' to '
        if (this.selected.otherGift.beneficiary_group)
          other += 'your ' + this.selected.otherGift.beneficiary_group
        else if (this.selected.otherGift.beneficiary.full_name)
          other += this.selected.otherGift.beneficiary.full_name
        return other
      } else if (this.selected.charityGift) {
        return (
          'Delete your gift of £' +
          this.selected.charityGift.amount +
          ' to ' +
          this.selected.charityGift.charity_name
        )
      }
      return ''
    }
  },
  data() {
    return {
      show: {
        cashModal: false,
        otherModal: false,
        propertyModal: false,
        charityModal: false,
        vehicleModal: false,
        deleteModal: false
      },
      selected: {
        cashGift: null,
        otherGift: null,
        propertyGift: null,
        charityGift: null,
        vehicleGift: null
      }
    }
  },
  methods: {
    createCashGift() {
      this.show.cashModal = true
    },
    createPropertyGift() {
      this.show.propertyModal = true
    },
    createVehicleGift() {
      this.show.vehicleModal = true
    },
    createOtherGift() {
      this.show.otherModal = true
    },
    createCharityGift() {
      this.show.charityModal = true
    },
    editCashGift(value) {
      this.selected.cashGift = value
      this.show.cashModal = true
    },
    editVehicleGift(value) {
      this.selected.vehicleGift = value
      this.show.vehicleModal = true
    },
    editPropertyGift(value) {
      this.selected.propertyGift = value
      this.show.propertyModal = true
    },
    editOtherGift(value) {
      this.selected.otherGift = value
      this.show.otherModal = true
    },
    editCharityGift(value) {
      this.selected.charityGift = value
      this.show.charityModal = true
    },
    closeCashModal() {
      this.show.cashModal = false
      this.selected.cashGift = null
    },
    closeOtherModal() {
      this.show.otherModal = false
      this.selected.otherGift = null
    },
    closePropertyModal() {
      this.show.propertyModal = false
      this.selected.propertyGift = null
    },
    closeVehicleModal() {
      this.show.vehicleModal = false
      this.selected.vehicleGift = null
    },
    closeCharityModal() {
      this.show.charityModal = false
      this.selected.charityGift = null
    },
    closeDeleteModal() {
      this.clearSelected()
      this.show.deleteModal = false
    },
    deleteGift() {
      var id
      var url
      var commit
      if (this.selected.cashGift) {
        id = this.selected.cashGift.id
        url = '/wills/api/gift_cash'
        commit = 'giftCashDelete'
      } else if (this.selected.charityGift) {
        id = this.selected.charityGift.id
        url = '/wills/api/gift_charity'
        commit = 'giftCharityDelete'
      } else if (this.selected.otherGift) {
        id = this.selected.otherGift.id
        url = '/wills/api/gift_other'
        commit = 'giftOtherDelete'
      } else if (this.selected.vehicleGift) {
        id = this.selected.vehicleGift.id
        url = '/wills/api/gift_vehicle'
        commit = 'giftVehicleDelete'
      } else if (this.selected.propertyGift) {
        id = this.selected.propertyGift.id
        url = '/wills/api/gift_real_estate'
        commit = 'giftRealEstateDelete'
      }
      http
        .delete(url, { params: { id: id } })
        .then((response) => {
          this.show.deleteModal = false
          this.$notification.success('Gift removed from your Will')
          this.$store.commit(commit, id)
          this.clearSelected()
        })
        .catch((error) => console.log(error))
    },
    deleteCashGift(value) {
      this.selected.cashGift = value
      this.show.deleteModal = true
    },
    deleteOtherGift(value) {
      this.selected.otherGift = value
      this.show.deleteModal = true
    },
    deletePropertyGift(value) {
      this.selected.propertyGift = value
      this.show.deleteModal = true
    },
    deleteCharityGift(value) {
      this.selected.charityGift = value
      this.show.deleteModal = true
    },
    deleteVehicleGift(value) {
      this.selected.vehicleGift = value
      this.show.deleteModal = true
    },
    clearSelected() {
      this.selected.cashGift = null
      this.selected.otherGift = null
      this.selected.propertyGift = null
      this.selected.charityGift = null
      this.selected.vehicleGift = null
    }
  }
}
</script>
